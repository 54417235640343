<template>
  <!-- 小游戏 -->
  <div class="Game">
    <div class="wrap" :style="{ height: height > 1000 ? '1200px' : '500px' }">
      <div
        class="text"
        :style="{
          transform: height > 1000 ? 'translateY(200px)' : 'translateY(70px)',
        }"
      >
        <div class="title">第{{ num }}关</div>
        <div
          class="box"
          :style="{
            height: height > 1000 ? '680px' : '290px',
            width: height > 1000 ? '500px' : '250px',
          }"
        >
          <span
            v-for="(item, index) in text"
            :key="index"
            @click="select(item, index)"
            ref="txt"
            :class="{ border: item == ' ' && index == index1 }"
            :style="{
              height: height > 1000 ? '80px' : '40px',
              width: height > 1000 ? '80px' : '40px',
              'line-height': height > 1000 ? '80px' : '40px',
            }"
            >{{ item
            }}<img
              src="@/assets/img/delete.png"
              alt=""
              ref="image"
              @click="del(index)"
              class="image"
          /></span>
        </div>
        <div
          class="select"
          :style="{ width: height > 1000 ? '500px' : '250px' }"
        >
          <span
            v-for="(item, index) in text1"
            :key="index"
            @click="ipt(item)"
            :style="{
              height: height > 1000 ? '80px' : '40px',
              width: height > 1000 ? '80px' : '40px',
              'line-height': height > 1000 ? '80px' : '40px',
            }"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="submit">
        <img src="@/assets/img/wancheng.png" alt="" />
      </div>
    </div>
    <!-- 游戏完成 -->
    <div class="success" v-if="success_show" @click.self="success_show = false">
      <div class="box" :style="{ height: height > 1000 ? '600px' : '300px' }">
        <div class="title">
          <img src="@/assets/img/game_success.png" alt="" />
        </div>
        <div class="text">
          <span class="p1">正确答案</span>
          <span class="p2">{{ text }}</span>
        </div>
        <div class="text">
          <span class="p1">注释</span>
          <span class="p2">{{ notes }}</span>
        </div>
        <div class="tips" v-if="tong">恭喜你已通关！可选择重新挑战。</div>
      </div>
      <div class="btn">
        <img src="@/assets/img/reset.png" alt="" @click="reset" />
        <img src="@/assets/img/next.png" alt="" @click="next" v-if="!tong" />
        <img src="@/assets/img/reset1.png" alt="" @click="reset1" v-else />
      </div>
    </div>
    <!-- 游戏失败 -->
    <div class="fail" v-if="fail_show" @click.self="fail_show = false">
      <div class="box" :style="{ height: height > 1000 ? '600px' : '300px' }">
        <img src="@/assets/img/game_fail.png" alt="" />
        <img src="@/assets/img/reset.png" alt="" @click="reset" />
      </div>
    </div>
    <div class="wechat_popup" @click.self="hide_wechat">
      <div class="box">
        <div class="title">分享至</div>
        <div class="imgs">
          <img src="@/assets/img/haoyou.png" alt="" @click="share(1)" />
          <img src="@/assets/img/pengyouquan.png" alt="" @click="share(0)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerName: "小游戏",
      msg: "", // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      text: "", // 题目
      text_copy: "", // 原题目
      text1: [], // 答案选项
      num: 0, // 关数
      success_show: false,
      fail_show: false,
      index1: "",
      textarr: [], // 提交答案
      id: "", // 题目id
      notes: "",
      newobj: {},
      number: "",
      tong: false,
      user: {},
      height: "",
    };
  },
  created() {
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
    }
  },
  mounted() {
    this.height = document.documentElement.clientHeight;
    let url = `/game/info`;
    this.getdata(url);
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
      $(".header_left").hide();
    } else {
      $(".header_left").show();
    }
    // 获取用户信息
    this.$get("/User/index", {}, (res) => {
      if (res.data.code == 1) {
        this.user = res.data.data;
      }
    });
  },
  methods: {
    // 显示分享
    show_share() {
      $(".wechat_popup").css("display", "block");
      $(".wechat_popup > .box").animate({
        bottom: 0,
      });
    },
    // 隐藏微信分享
    hide_wechat() {
      $(".wechat_popup > .box").animate({
        bottom: -120 + "px",
      });
      setTimeout(() => {
        $(".wechat_popup").css("display", "none");
      }, 400);
    },
    // 分享
    share(type) {
      // if (/LT-APP/.test(navigator.userAgent)) {
      //   jsBridge.share({
      //     //0 微信朋友圈
      //     //1 微信好友
      //     //2 QQ好友
      //     //3 QQ空间
      //     to: type,
      //     title: "小游戏",
      //     link: location.href + `?invite_code=${this.user.invite_code}`,
      //     imgUrl: "../../assets/img/logo@2x.png",
      //     desc: "填字小游戏",
      //   });
      // }
    },
    // 获取数据
    getdata(url) {
      this.$get(url, {}, (res) => {
        if (res.data.code == 1) {
          this.id = res.data.data.id;
          this.num = res.data.data.weigh;
          this.text = res.data.data.subject.replace(/\*/g, " ");
          this.text_copy = res.data.data.subject;
          this.text1 = res.data.data.answer;
          this.notes = res.data.data.notes;
          this.index1 = this.text.indexOf(" ");
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      });
    },
    // 点击空格
    select(item, index) {
      if (item == " ") {
        this.index1 = index;
      }
    },
    // 填充字
    ipt(txt) {
      if (this.index1 || this.index1 === 0) {
        this.text = this.text.split(""); //将baia字符串转换成数组
        this.text.splice(this.index1, 1, txt); //将1这个位置的字符，替换成'xxxxx'.用的是原生js的splice方法。
        this.text = this.text.join(""); //将数组转换成字符串.完成。
        var img = this.$refs.image[this.index1];
        $(img).css("display", "block");
        if (this.text.indexOf(" ") != -1) {
          this.index1 = this.text.indexOf(" ");
        }
      }
    },
    // 清除
    del(i) {
      this.text = this.text.split(""); //将baia字符串转换成数组
      this.text.splice(i, 1, " "); //将1这个位置的字符，替换成'xxxxx'.用的是原生js的splice方法。
      this.text = this.text.join(""); //将数组转换成字符串完成。
      var img = this.$refs.image[i];
      $(img).css("display", "none");
    },
    // 提交
    submit() {
      var txt = this.text;
      var txt2 = this.text_copy;
      txt = txt.split("");
      txt2 = txt2.split("");
      let a = [];
      for (let i = 0; i < txt.length; i++) {
        if (txt[i] != txt2[i]) {
          a.push(txt[i]);
        }
      }
      a = a.join("*");
      this.$post("/game/check", { gameid: this.id, answer: a }, (res) => {
        if (res.data.code == 1) {
          if (res.data.data == null) {
            this.tong = true;
          } else {
            this.newobj = {
              id: res.data.data.id,
              num: res.data.data.weigh,
              text: res.data.data.subject.replace(/\*/g, " "),
              text_copy: res.data.data.subject,
              text1: res.data.data.answer,
              notes: res.data.data.notes,
            };
            this.success_show = true;
          }
        } else {
          this.fail_show = true;
        }
      });
    },
    // 下一题
    next() {
      this.text = this.newobj.text;
      this.text_copy = this.newobj.text_copy;
      this.text1 = this.newobj.text1;
      this.notes = this.newobj.notes;
      this.success_show = false;
      this.getdata(`/game/info?gameid=${`` + this.id + ``}&type=after`);
      $(".image").css("display", "none");
    },
    // 重新答题
    reset() {
      this.fail_show = false;
      this.success_show = false;
      this.getdata(`/game/info?gameid=${`` + this.id + ``}&type=this`);
      $(".image").css("display", "none");
    },
    // 重新挑战
    reset1() {
      this.fail_show = false;
      this.success_show = false;
      this.getdata(`/game/info?type=one`);
      $(".image").css("display", "none");
    },
  },
};
</script>

<style lang='scss' scoped>
.Game {
  width: 100%;
  height: 100vh;
  background: #f2ecd4;
  .bg {
    position: absolute;
    top: 50px;
    width: 100%;
    height: 50px;
  }
  .wrap {
    position: relative;
    top: 70px;
    left: 0;
    width: 100%;
    height: 500px;
    background: url("../assets/img/game_bg.png");
    background-size: 100% 100%;
    .text {
      width: 65%;
      text-align: center;
      margin: 0 auto;
      transform: translateY(70px);
      .title {
        font-size: 30px;
        font-weight: bold;
      }
      .box {
        width: 250px;
        height: 290px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: 10px;
        span {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-weight: bold;
          background: #f0d293;
          margin-right: 5px;
          border-radius: 10px;
          img {
            display: none;
            position: absolute;
            right: -3px;
            top: -3px;
            width: 15px;
            height: 15px;
          }
        }
      }
      .select {
        display: flex;
        justify-content: space-around;
        width: 250px;
        margin: 0 auto;
        border: 2px solid #eaa24d;
        span {
          display: inline-block;
          width: 40px;
          height: 40px;
          background: #f0d293;
          margin: 5px 0;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-weight: bold;
          border-radius: 10px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    padding-top: 100px;
    background: #f2ecd4;
    .btn {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 10px 0;
      img {
        width: 50%;
      }
    }
    .title {
      padding-left: 10px;
      border-left: 5px solid #ab0404;
      font-size: 16px;
      color: #ab0404;
    }
    .image {
      width: 100%;
      height: 100px;
      margin: 10px 0;
      border-radius: 10px;
    }
  }
}
.share {
  position: fixed;
  top: 14px;
  right: 10px;
  z-index: 2;
  font-size: 16px;
  font-weight: bold;
  img {
    width: 20px;
  }
}
// 游戏完成
.success {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 3;
  .box {
    position: fixed;
    left: 10%;
    top: 25%;
    width: 80%;
    height: 300px;
    background: #f2e8c7;
    box-shadow: inset 2px 2px 50px 10px #e6a045;
    border-radius: 10px;
    .title {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      img {
        width: 70%;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      width: 70%;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      .p1 {
        font-size: 16px;
      }
      .p2 {
        font-size: 14px;
        color: #525252;
      }
    }
    .tips {
      text-align: center;
      color: red;
      margin-top: 30px;
      font-size: 14px;
    }
  }
  .btn {
    position: fixed;
    left: 10%;
    top: 72%;
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    img {
      width: 130px;
    }
  }
}
// 游戏失败
.fail {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 3;
  .box {
    position: fixed;
    left: 10%;
    top: 25%;
    width: 80%;
    height: 300px;
    background: #f2e8c7;
    box-shadow: inset 2px 2px 50px 10px #e6a045;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
      margin: 30px 0;
    }
  }
}
.wechat_popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  .box {
    position: absolute;
    bottom: -150px;
    background: white;
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;
    .title {
      text-align: center;
      font-size: 16px;
      margin-top: 10px;
    }
    .imgs {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
      img {
        width: 50px;
        height: 60px;
      }
    }
  }
}
.border {
  border: 1px dashed #999 !important;
}
</style>